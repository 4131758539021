import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, createTheme, ThemeProvider } from '@mui/material';
import { Typography, Box } from '@mui/material';
import moment from 'moment';

// Define a custom theme with styles for TableCell
const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: '1px solid black',
          padding: '8px', // Adjust padding as needed
        },
        head: {
          fontWeight: 'bold',
        },
        inOutCell: {
          minWidth: 40, // Adjust the width as needed
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#f0f0f0', // Shallow grey color
        },
      },
    },
  },
});

function getTimeOfDay(epoch) {
  const date = moment(epoch); // Convert epoch time to moment object
  const hour = date.hour(); // Extract hour component

  if (hour >= 8 && hour < 12) {
      return "morning";
  } else if (hour >= 12 && hour < 18) {
      return "afternoon";
  } else {
      return "OT";
  }
}

function calculateTimeIntervalInHours(startTimeStr, endTimeStr) {
  // Parse the time strings using moment
  const startTime = moment(startTimeStr, 'HH:mm:ss');
  const endTime = moment(endTimeStr, 'HH:mm:ss');

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = endTime.diff(startTime);

  // Convert milliseconds to hours with decimal fractions
  const differenceInHours = moment.duration(differenceInMilliseconds).asHours();

  return differenceInHours.toFixed(1);
}

const DataTable = ({ empInfo={
    name: '',
    date: "",
    position: '',
    wages: '',
    record: []
}}) => {
  // Calculate grand total
  let grandTotal = 0;

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ mb: 3 }}>
        <Typography>
          Name: {empInfo.name}
        </Typography>
        <Typography>
          Position: {empInfo.position}
        </Typography>
        <Typography>
          Total Wages: RM {empInfo.wages}
        </Typography>
        <Typography>
          Total worked hour(s): 1
        </Typography>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2}>Day</TableCell>
              <TableCell colSpan={2}>Morning</TableCell>
              <TableCell colSpan={2}>Afternoon</TableCell>
              <TableCell colSpan={2}>OT</TableCell>
              <TableCell rowSpan={2}>Hrs</TableCell>
              <TableCell rowSpan={2}>Total</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ minWidth: 70 }}>In</TableCell>
              <TableCell sx={{ minWidth: 70 }}>Out</TableCell>
              <TableCell sx={{ minWidth: 70 }}>In</TableCell>
              <TableCell sx={{ minWidth: 70 }}>Out</TableCell>
              <TableCell sx={{ minWidth: 70 }}>In</TableCell>
              <TableCell sx={{ minWidth: 70 }}>Out</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              empInfo.record.map((row, index) => {
                let dayRecordList = row.dayRecord;
                let totalDayHr = 0;

                //calculate total length of one shift
                let morningInTime = "";
                let morningOutTime = "";

                let afInTime = "";
                let aftOutTime = "";

                let OTIn = "";
                let OTOut = "";

                console.log(dayRecordList);

                let currentIn = "";
                let currentOut = "";
                dayRecordList.forEach(cur=>{
                  if(cur.type == "IN"){
                    currentIn = cur.punchTime;

                    if(getTimeOfDay(cur.punchDateTime) == "morning"){
                      morningInTime = cur.punchTime;
                    }else if(getTimeOfDay(cur.punchDateTime) == "afternoon"){
                      afInTime = cur.punchTime;
                    }else if(getTimeOfDay(cur.punchDateTime) == "OT"){
                      OTIn = cur.punchTime;
                    }
                  }else if(cur.type == "OUT"){
                    currentOut = cur.punchTime;

                    if(getTimeOfDay(cur.punchDateTime) == "morning"){
                      morningOutTime = cur.punchTime;
                    }else if(getTimeOfDay(cur.punchDateTime) == "afternoon"){
                      aftOutTime = cur.punchTime;
                    }else if(getTimeOfDay(cur.punchDateTime) == "OT"){
                      OTOut = cur.punchTime;
                    }
                  }

                  if(currentIn != "" && currentOut != ""){
                    totalDayHr += Number(calculateTimeIntervalInHours(currentIn, currentOut));
                    currentIn = "";
                    currentOut = "";
                  }
                })

                //calculate hour
                grandTotal += totalDayHr*empInfo.wages;

                return (
                  <TableRow key={index} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>{morningInTime}</TableCell>
                    <TableCell>{morningOutTime}</TableCell>
                    <TableCell>{afInTime}</TableCell>
                    <TableCell>{aftOutTime}</TableCell>
                    <TableCell>{OTIn}</TableCell>
                    <TableCell>{OTOut}</TableCell>
                    <TableCell>{totalDayHr}</TableCell>
                    <TableCell>{totalDayHr*empInfo.wages}</TableCell>
                    {/* <TableCell>{row.morning.in}</TableCell>
                    <TableCell>{row.morning.out}</TableCell>
                    <TableCell>{row.afternoon.in}</TableCell>
                    <TableCell>{row.afternoon.out}</TableCell>
                    <TableCell>{row.afternoon.in}</TableCell>
                    <TableCell>{row.afternoon.out}</TableCell>
                    <TableCell>{row.hrs}</TableCell>
                    <TableCell>{row.total}</TableCell> */}
                  </TableRow>
                )
              })
            }
            {/* Display grand total row */}
            <TableRow>
              <TableCell colSpan={8} align="right">Grand Total: RM</TableCell>
              <TableCell>{grandTotal}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

export default DataTable;
