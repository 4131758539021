import React, {useState, useEffect} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';

import {db} from '../../firebase';
import { collection, getDocs } from "firebase/firestore"; 

import MainLayout from "../../components/mainLayout";
import ContentLayout from "../../components/contentLayout";

const columns = [
    { field: 'recordId', headerName: 'recordID', width: 70},
    // { field: 'id', headerName: 'No.', width: 70},
    { field: 'punchTime', headerName: 'Time', flex: 1 },
    { field: 'type', headerName: 'Clock In/Out', flex: 1 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'punchDate', headerName: 'Date', flex: 1 },
    { field: 'punchDateTime', headerName: 'DateTime', flex: 1 },
    // { field: 'isLate', headerName: 'Is Late', width: 150 },
];


export default function Index(){
    const [rows, setRows] = useState([]);
    const [openModal, setOpenModal] = useState("");

    const [selectedDateTime, setSelectedDateTime] = useState([ moment().format('YYYYMM'), moment().format('DD')]); //yearmonth, day

    useEffect(()=>{
        fetchData();
    },[]);

    async function fetchData(){
        console.log('fetch punch data');
        let newRows = [];
        let idx = 0;

        const querySnapshot = await getDocs(collection(db, "PCSystem", "Mitoto", "Attendance", selectedDateTime[0], selectedDateTime[1]));
        querySnapshot.forEach((doc) => {
            idx = idx + 1;
            console.log(doc.data());
            newRows.push(
                {
                    // id: idx.toString(),
                    id: doc.id, 
                    recordId: doc.id, 
                    type: doc.data().isClockIn ? "IN" : "OUT",
                    punchDate: moment.unix(doc.data().timestamp/1000).format('YYYY MMM'),
                    punchTime: moment.unix(doc.data().timestamp/1000).format('HH:mm:ss'),
                    punchDateTime: doc.data().timestamp,
                    name: doc.data().name,
                }
            );
        });

        let sortedList = newRows.sort((a, b) => b.punchDateTime - a.punchDateTime);
        setRows(sortedList);
    }

    const handleOnClose = (isSuccess) =>{
        if(isSuccess == true) fetchData();
        setOpenModal("");
    }

    return (
        <MainLayout name="Clock In/Out List">
            <ContentLayout title="Clock In/Out List">
                <div style={{ minHeight: 170, width: '100%' }}>
                    <DataGrid 
                        disableRowSelectionOnClick
                        rows={rows} 
                        columns={columns} 
                        initialState={{
                            columns: {
                              columnVisibilityModel: {
                                recordId: false,
                                punchDateTime: false,
                              },
                            }
                        }}
                    />
                </div>
            </ContentLayout>
        </MainLayout>
    )
}