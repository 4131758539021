import React, {useState, useEffect} from 'react';
import { DataGrid } from '@mui/x-data-grid';

import {db} from '../../firebase';
import { collection, getDocs } from "firebase/firestore"; 

import MainLayout from "../../components/mainLayout";
import ContentLayout from "../../components/contentLayout";
import AddAdmin from "./addAdmin";

const columns = [
    { field: 'id', headerName: 'No.', width: 70},
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
];


export default function Index(){
    const [rows, setRows] = useState([]);
    const [openModal, setOpenModal] = useState("");

    useEffect(()=>{
        fetchData();
    },[]);

    async function fetchData(){
        console.log('fetch admin data');
        let newRows = [];
        let idx = 0;
        const querySnapshot = await getDocs(collection(db, "PCSystem", "Mitoto", "Admin"));
        querySnapshot.forEach((doc) => {
            idx = idx + 1;
            newRows.push({id: idx.toString(), email: doc.id, name: doc.data().name});
        });

        setRows(newRows);
    }

    const handleOnClose = (isSuccess) =>{
        if(isSuccess == true) fetchData();
        setOpenModal("");
    }

    return (
        <MainLayout name="Admin">
            <ContentLayout title="Admin" addBtnName="Add Admin" handleClickAdd={()=>setOpenModal("A")}>
                <div style={{ minHeight: 170, width: '100%' }}>
                    <DataGrid 
                        disableRowSelectionOnClick
                        rows={rows} 
                        columns={columns} 
                    />
                </div>
            </ContentLayout>

            <AddAdmin visible={openModal == "A"} returnClose={(res)=>handleOnClose(res)}/>
        </MainLayout>
    )
}