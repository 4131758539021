import React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function TheTextField({id, label, value="", type="text", placeholder=""}) {
    return (
        <div className="mb-3">
            <Typography variant="h6" sx={{fontWeight:'bold'}}>
                {label}
            </Typography>
            <TextField 
                key={value}
                size="small"
                sx={{width:'80%' , mb:2}}
                name={id}
                type={type} 
                placeholder={placeholder}
                defaultValue={value}
            />
        </div>
    )
}