import './App.css';
import {Routes, Route, BrowserRouter } from "react-router-dom"
import React from 'react';

import Login from './pages/login/layout';
import Admin from './pages/admin/layout';

import Dashboard from './pages/dashboard/layout';
import Employee from './pages/employee/layout';
import ClockInOut from './pages/clockInOut/layout';
import PunchCard from './pages/punchCard/layout';
import EmployeeReport from './pages/employeeReport/layout';

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/employee" element={<Employee />} />
          <Route exact path="/employee/clockInOut" element={<ClockInOut />} />
          <Route exact path="/employee/employeeReport" element={<EmployeeReport />} />
          
          <Route exact path="/punchCard" element={<PunchCard />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
