import React, {useState, useEffect} from 'react';
import { Typography } from "@mui/material";
import { Box, Button } from '@mui/material';
import Cookies from 'js-cookie';
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {db, auth} from '../../firebase';
import { collection, getDocs, setDoc, addDoc, deleteDoc, doc} from "firebase/firestore"; 
import {signInWithEmailAndPassword, onAuthStateChanged, signOut} from 'firebase/auth';

import ContentLayout from "../../components/contentLayout";
import LiveClock from "../../components/other/liveClock";
import TheTextField from '../../components/common/theTextField';

export default function Index(){
    const yearMonth = moment().format('YYYYMM');
    const day = moment().format('DD');

    const [user, setUser] = useState(null);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [empData, setEmpData] = useState([]);
    const [workingEmp, setWorkingEmp] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState(0);
    const [pw, setPw] = useState("");

    useEffect(()=>{
        onAuthStateChanged(auth, (user)=>{
            if(user){
                setUser(user);
                getEmployeeData();
                getCurrentAttendance();
            }else{
                setUser(null);
            }
        });

        //(when user not authorized)
        const email = Cookies.get('ps_email');
        const password = Cookies.get('ps_password');

        console.log(email != null && password != null);

        console.log(email);
        console.log(password);

        if(email != null && password != null){
            doLogin(email, password);
        }
    }, []);

    async function getCurrentAttendance(){
        console.log('fetch current attendance');
        let newRows = [];
        let idx = 0;
        const querySnapshot = await getDocs(collection(db, "PCSystem", "Mitoto", "CurrentAttendance"));
        querySnapshot.forEach((doc) => {
            idx = idx + 1;
            let curRow = {
                userId: doc.id,
                id: idx,
                name: doc.data().name,
                clockInAt: doc.data().timestamp,
            }

            newRows.push(curRow);
        });

        setWorkingEmp(newRows);
    }

    async function getEmployeeData(){
        console.log('fetch employee data');
        let newRows = [];
        let idx = 0;
        const querySnapshot = await getDocs(collection(db, "PCSystem", "Mitoto", "Employee"));
        querySnapshot.forEach((doc) => {
            idx = idx + 1;
            let curRow = {
                userId: doc.id,
                id: idx,
                usrId: doc.id,
                ic: doc.data().ic,
                name: doc.data().name,
            }

            newRows.push(curRow);
        });

        setEmpData(newRows);
    }

    const handleChange = (event) => {
        setSelectedStaff(event.target.value);
      };


    const setTheCookie = (name, value, forever) =>{
        const expirationDate = new Date();
        if(forever){
            expirationDate.setFullYear(expirationDate.getFullYear() + 50); // Expires in 50 years
        }
        else{
            expirationDate.setHours(expirationDate.getHours() + 5); // Expires in 5 hours
        }

        Cookies.set(name, value, { expires: expirationDate });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let errMsg = "";

        let email = data.get('username');
        let password = data.get('password');

        doLogin(email, password);

    };

    const doLogin = (email, password) =>{
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential)=>{
            console.log(userCredential);
            setTheCookie('ps_email', email, true);
            setTheCookie('ps_password', password, true);


        })
        .catch((error) =>{
            alert('error!');
            console.log(error);
        });
    }

    const logout = () =>{
        signOut(auth).then(()=>{
          alert('logged out');
        }).catch((error)=>{alert('logout error!');console.log(error)});;
    }

    const performClock = async () =>{
        console.log('perform clock');

        let thisEmp = empData[selectedStaff-1];

        // if(pw != thisEmp.id){
        //     alert('Incorrect IC, please retry!');
        //     return;
        // }

        //1 - check if clocked in
        let isClockIn = workingEmp.findIndex((item)=>item.userId == thisEmp.userId) > -1;

        //2 - record it
        let input = {
            id: thisEmp.userId,
            name: thisEmp.name,
            timestamp: moment().valueOf(),
            isClockIn: !isClockIn,
        }
        
        await addDoc(collection(db, "PCSystem", "Mitoto", "Attendance", yearMonth, day), input)
        .then(res=>{
            //3 - update the current attendance
            if(isClockIn){ //if clocked in, delete the record
                deleteDoc(doc(db, "PCSystem", "Mitoto", "CurrentAttendance", thisEmp.userId))
                .then(()=>{
                    alert(`${input.name} is Clock Out Successfully!`);
                    getCurrentAttendance();
                });
            }
            else{ //if not yet clock in, add the record
                setDoc(doc(db, "PCSystem", "Mitoto", "CurrentAttendance", thisEmp.usrId), input)
                .then(()=>{
                    alert(`${input.name} is Clock In Successfully!`);
                    getCurrentAttendance();
                });
            }
        }).catch(err=>{
            alert('Error on clockin/out!');
            console.log('Error adding document: ', err);
        });
    }

    
    return (
        <>
            {user?
                (<Box sx={{padding: 2}}>
                    <ContentLayout title="Punch Card" sx={{display:'flex'}}>
                        <Typography variant="h6">
                            Today is: 
                            <LiveClock />
                        </Typography>
                        {empData.length > 0 ? 
                        <>
                        <Typography variant="h6">
                            {workingEmp.length} employees have clocked in today.
                        </Typography>
        
                        <FormControl sx={{ m: 1, minWidth: 120, width: '80%', mb: 2 }}>
                            <Select
                                value={selectedStaff}
                                onChange={handleChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
        
                            {empData.map((item, index)=>{
                                return (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                )
                            })}
                            </Select>
                        </FormControl>
                        
                        {selectedStaff != 0 && 
                            <>
                                {/* <Typography variant="h6">
                                    Enter your IC to clock in/out:
                                </Typography>
                                <TextField type="text" id="margin-normal" margin="normal" onKeyUp={(e)=>setPw(e.target.value)}/> */}
                                <Button variant="contained" color="primary" type="button" sx={{marginRight: 2}} onClick={()=>performClock()}>Clock In/Out</Button>
                            </>
                        }
                    </>
                            : <></>}
                        <Button variant="contained" color="error" type="button" sx={{marginRight: 2, mt: 100}} onClick={()=>logout()}>test Logout</Button>
                    </ContentLayout>
                </Box>) : 
                Cookies.get('ps_email') == null && Cookies.get('ps_password') == null ?
                (
                <>
                    <Typography variant="h6">
                        Login to your staff account to access this page.

                        <Box component="form" noValidate onSubmit={handleSubmit}>
                            <TheTextField id="username" label="Username"/>
                            <TheTextField id="password" label="Password" placeholder="Enter Password"/>
            
                            <Box sx={{display:'flex', justifyContent:"center"}}>
                                <Button variant="contained" color="primary" type="submit" sx={{marginRight: 2}}>Login</Button>
                            </Box>
                        </Box>
                    </Typography>
                </>)
                : (<>Loading...</>)
            }
        </>
    )
}