import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

import moment from 'moment';

export default function TheDatePicker({returnType="string", onChange, defaultIsToday=false}) {
    const [openPicker, setOpenPicker] = useState(false);

    const updateDate = (val) =>{
        let dtStr = val["$d"];
        setOpenPicker(false);

        if(returnType == "date"){
            onChange(new Date(dtStr));
        }
        else if(returnType == "string"){
            onChange(moment(new Date(dtStr)).format('DD-MM-YYYY'));
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                maxDate = {dayjs(moment.now()) }
                defaultValue={defaultIsToday ? dayjs(moment.now()) : null}
                open={openPicker}
                onChange={val=>updateDate(val)}
                openTo="month"
                views={['year', 'month', 'day']}
                label="Select Date"
                format="MM/YYYY" // Set the format to MM/YYYY
                renderInput={(params) => <TextField {...params} helperText="MM/YYYY" />} // Display helper text MM/YYYY
                slotProps={{
                    textField:{
                        placeholder: "No Date Selected",
                        onClick: (e) => setOpenPicker(true),
                        size: "small",
                        readOnly: true
                    }
                }}
            />
        </LocalizationProvider>
    )
}