import React from 'react';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import moment from 'moment'

import {auth, db} from '../../firebase.js';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc } from "firebase/firestore"; 

import TheModal from '../../components/common/theModal';
import TheTextField from '../../components/common/theTextField';

export default function AddAdmin({visible=false, returnClose}){
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let errMsg = "";
    
        let name = data.get('name');
        let email = data.get('email');
        let password = data.get('password');
        let password2 = data.get('password2');

        errMsg = validateInput(name, email, password, password2);

        if(errMsg.length == 0){
            createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential)=>{
                console.log(userCredential);
                //Insert email into fb
                let input = {
                    name: name,
                    email: email,
                    // password: password,
                    role: 1,
                    createdAt: moment().valueOf()
                };
        
                await setDoc(doc(db, "PCSystem", "Mitoto", "Admin", email), input);
                returnClose(true);
                alert('Created Admin Successfully!');
            })
            .catch((error) =>{
                console.log(error);
                let errorCode = error.code;

                if(errorCode == 'auth/invalid-email') alert('Invalid Email');
                else if(errorCode == "auth/email-already-in-use") alert('Email already in use, please use other email.')
                else alert('Unknown error');
            });
        }
        else{
            alert(errMsg);
        }
    };

    const validateInput = (name, email, pw, pw2) =>{
        if(!name){
            return "Name is required";
        }
        else if(!email){
            return "Email is required";
        }
        else if(email){
            if(!email.includes('@')){
                return "Invalid email";
            }
        }
        
        if(pw && pw.length < 6){
            return "Password must be at least 6 characters";
        }
        else if(!pw2){
            return "Please confirm your password";
        }
        else if(pw != pw2){
            return "Passwords do not match";
        }

        return "";
    }

    return (
        <TheModal title="Add Admin" visible={visible} returnClose={res=>returnClose(res)}>
            <Box component="form" noValidate onSubmit={handleSubmit}>
                <TheTextField id="name" label="Name" placeholder="This admin's name"/>
                <TheTextField id="email" label="Email" placeholder="XXX@gmail.com"/>
                <TheTextField id="password" label="Password" placeholder="Enter New Password"/>
                <TheTextField id="password2" label="Confirm Password" placeholder="Confirm your password"/>

                <Divider sx={{my:2}}/>

                <Box sx={{display:'flex', justifyContent:"center"}}>
                    <Button variant="contained" color="primary" type="submit" sx={{marginRight: 2}}>Create</Button>
                    <Button variant="contained" color="secondary" onClick={res=>returnClose(res)}>Close</Button>
                </Box>
            </Box>
        </TheModal>
    )
}
