import React, { Component } from "react";
import { Typography } from "@mui/material";

class LiveClock extends Component {
  constructor(props) {
    super(props);

    this.state = { date: new Date() };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      date: new Date()
    });
  }

  render() {
    const { date } = this.state;
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZone: "Asia/Kuala_Lumpur" // Set the time zone to Malaysia
    };

    const formattedDate = date.toLocaleDateString("en-MY", options);

    return (
      <Typography variant="body1">
        {formattedDate}
      </Typography>
    );
  }
}

export default LiveClock;
