import React, {useEffect, useState} from 'react';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import moment from 'moment'

import {auth, db} from '../../firebase.js';
import { setDoc, addDoc, deleteDoc, doc, collection } from "firebase/firestore"; 

import TheModal from '../../components/common/theModal';
import TheTextField from '../../components/common/theTextField';

export default function AddEditEmployee({visibleType="", data={},returnClose}){
    const [employeeData, setEmployeeData] = useState({});

    useEffect(()=>{
        console.log(data);
        if(visibleType == "E" && data.id != undefined){
            let cur = data;
            cur.updatedAt = moment(cur.updatedAt).format("DD/MM/YYYY");
            setEmployeeData(cur);
            console.log('entered');
        }
        else{
            setEmployeeData({
                name: "",
                ic: "",
                phone: "",
                position: "",
                salary: "",
                dob: "",
            });
        }
    },[visibleType]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let errMsg = "";

        let input = {
            name: data.get('name'),
            ic: data.get('ic'),
            phone: data.get('phone'),
            updatedAt: moment().valueOf(),
            position: data.get('position'),
            salary: data.get('salary'),
            dob: data.get('dob') ? moment(data.get('dob'), "DD/MM/YYYY").valueOf() : null,
        }

        errMsg = validateInput(input.name, input.ic, input.phone);

        if(errMsg.length == 0){
            if(visibleType == "A"){
                await addDoc(collection(db, "PCSystem", "Mitoto", "Employee"), input)
                .then(res=>{
                    alert('Added Employee Successfully!');
                    returnClose(true);
                }).catch(err=>{
                    alert('Error on adding employee!');
                    console.log('Error adding document: ', err);
                });
            }
            else if(visibleType == "E"){
                await setDoc(doc(db, "PCSystem", "Mitoto", "Employee", employeeData.usrId), input)
                .then(res=>{
                    alert('Updated Employee Successfully!');
                    returnClose(true);
                }).catch(err=>{
                    alert('Error on updating employee!');
                    console.log('Error adding document: ', err);
                });
            }
        }
        else{
            alert(errMsg);
        }
    };

    const deleteEmployee = async () =>{
        if(window.confirm("Are you sure to delete "+ employeeData.name + "?")){
            deleteDoc(doc(db, "PCSystem", "Mitoto", "Employee", employeeData.usrId))
            .then(() => {
                alert("Deleted "+ employeeData.name + " Successfully!");
                returnClose(true);
            })
            .catch((err) => {
                alert('Error deleting employee!');
                console.error('Error deleting document: ', err);
            });
        }
    }

    const validateInput = (name, ic, phone) =>{
        if(!name){
            return "Name is required";
        }
        else if(!ic){
            return "IC Number is required";
        }
        else if(!phone){
            return "Phone is required";
        }
        
        return "";
    }

    return (
        <TheModal title={(visibleType == "A"? "Add" : "Edit" ) + " Employee" + (visibleType == "E" ? " (" + employeeData.name + ")" : "")} visible={visibleType.length > 0} returnClose={res=>returnClose(res)}>
            <Box component="form" noValidate onSubmit={handleSubmit}>
                <TheTextField id="name" label="Employee Name" placeholder="This employee's name" value={employeeData.name}/>
                <TheTextField id="ic" label="IC Number (Number Only)" type="number" placeholder="Employee Identity Card Number" value={employeeData.ic}/>
                <TheTextField id="phone" label="Phone Number (Number Only)" type="number" placeholder="Phone Number (E.g: 0178117417)" value={employeeData.phone}/>
                <TheTextField id="salary" label="Salary per Hour (RM)" type="number" placeholder="E.g: 1000" value={employeeData.salary}/>
                <TheTextField id="dob" label="Birthday" type="text" placeholder="Format: 22/09/1998" value={employeeData.dob}/>
                <TheTextField id="position" label="Position" type="text" placeholder="Position" value={employeeData.position}/>

                <Divider sx={{my:2}}/>

                <Box sx={{display:'flex', justifyContent:"center"}}>
                    {visibleType == "E" ? 
                        <>
                            <Button variant="contained" color="error" sx={{marginRight: 2}} onClick={()=>deleteEmployee()}>Delete</Button>
                            <Button variant="contained" color="primary" type="submit" sx={{marginRight: 2}}>Update</Button>
                        </>
                    : <Button variant="contained" color="primary" type="submit" sx={{marginRight: 2}}>Create</Button>}
                    
                    <Button variant="contained" color="secondary" onClick={res=>returnClose(res)}>Close</Button>
                </Box>
            </Box>
        </TheModal>
    )
}
