import React, {useState, useEffect} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Select, InputLabel, MenuItem, FormControl, Button } from '@mui/material';
import moment from 'moment';

import {db} from '../../firebase';
import { collection, getDocs } from "firebase/firestore"; 

import MainLayout from "../../components/mainLayout";
import ContentLayout from "../../components/contentLayout";
import TheDatePicker from "../../components/other/TheDatePicker";
import WorkerRecord from "./workerRecord";

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

export default function Index(){
    const [rows, setRows] = useState([]);
    const [openModal, setOpenModal] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [staffList, setStaffList] = useState([ { "id": "FX2hyr5kN3dXqehrMSoP", "name": "Aaden", "position": 'Admin', 'wages': '19' }, { "id": "ZyfMkzooFwkERCzXAhcl", "name": "Aaron Wong", "position": 'Cashier', 'wages': '20' } ]);
    const [selectedDateTime, setSelectedDateTime] = useState(moment().format('YYYYMM')); //yearmonth, day
    const [selectedStaffId, setSelectedStaffId] = useState("");

    const [selectedRecord, setSelectedRecord] = useState(null);
    
    const [recordList, setRecordList] = useState([]);

    useEffect(()=>{
        //get staff list
        // getEmployeeData();
    },[]);

    const handleStaffChange = (event) => {
        setSelectedStaffId(event.target.value);
    };

    const handleDateChange = (date) => {
        setSelectedDateTime(moment(date, "DD-MM-YYYY").format('YYYYMM'));
    };

    const generateReport = async () =>{
        let yearmonth = selectedDateTime;

        const year = yearmonth.substring(0, 4);
        const month = yearmonth.substring(4);

        let totalDaysInMth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
        
        //0 Validation employee and date
        if(selectedStaffId == "" || yearmonth == ""){
            alert("Please select staff and date");
            return;
        }

        if(moment().format('YYYYMM') < yearmonth){
            alert("Cannot generate future report");
            return;
        }

        //1 Check is the report generated before
        setIsLoading(true);
        let existedRecord = [];
        let attendance = [];

        if(recordList.length > 0){
            existedRecord = recordList.find(x=>x.date == yearmonth);
        }
        
        console.log(existedRecord);
        if(existedRecord != null && existedRecord.date != undefined){
            attendance = existedRecord.record;
        }
        else{
            //2 get all report on that month
            const currentRecord = [];
    
            for(let i = 1; i <= totalDaysInMth; i++){
                console.log(i);
                const querySnapshot = await getDocs(collection(db, "PCSystem", "Mitoto", "Attendance", yearmonth, i.toString()));
    
                let currentMthRecord = [];
                querySnapshot.forEach((doc) => {
                    console.log(i + " " + doc.id);
    
                    currentMthRecord.push({
                        userId: doc.data().id, 
                        recordId: doc.id, 
                        type: doc.data().isClockIn ? "IN" : "OUT",
                        punchDate: moment.unix(doc.data().timestamp/1000).format('YYYY MMM'),
                        punchTime: moment.unix(doc.data().timestamp/1000).format('HH:mm:ss'),
                        punchDateTime: doc.data().timestamp,
                        name: doc.data().name,
                    });
                });
    
                currentRecord.push({
                    date: yearmonth,
                    day: i,
                    record: currentMthRecord
                })
            }
    
            let currentYearMthRecord = {
                date: yearmonth,
                record: currentRecord
            };
    
            //3 set record
            setRecordList([...recordList, currentYearMthRecord]);
            attendance = currentRecord;
        }

        let personalAttendance = [];
        console.log(attendance);
        for(let i = 1; i <= totalDaysInMth; i++){
            let idx = i-1;
            console.log(attendance[idx]);
            let drList = attendance[idx].record != undefined && attendance[idx].record.length > 0? attendance[idx].record.filter(x=>x.userId == selectedStaffId) : [];

            personalAttendance.push({
                day: i,
                dayRecord: drList
            });
        }
        console.log(personalAttendance);

        //last add into selectedRecord
        let userInfo = staffList.find(x=>x.id == selectedStaffId);
        setSelectedRecord({
            date: moment(yearmonth, "YYYYMM").format('MMMM YYYY'),
            name: userInfo.name,
            position: userInfo.position,
            wages: userInfo.wages,
            record: personalAttendance
        })

        setIsLoading(false);
    }

    async function getEmployeeData(){
        console.log('fetch employee data');
        let newRows = [];
        let idx = 0;
        const querySnapshot = await getDocs(collection(db, "PCSystem", "Mitoto", "Employee"));
        querySnapshot.forEach((doc) => {
            let curRow = {
                id: doc.id,
                name: doc.data().name,
            }

            newRows.push(curRow);
        });

        console.log(newRows);
        setStaffList(newRows);
    }

    return (
        <MainLayout name="Employee Attendance List">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => 9999999}}
                open={isLoading}
                onClick={()=>setIsLoading(!isLoading)}
            >
                <CircularProgress sx={{color: "#fff"}}/>
            </Backdrop>

            <ContentLayout title="Employee Attendance List">
                <div style={{ width: '100%' }}>
                    <FormControl sx={{mb: 2}}>
                        <InputLabel id="demo-simple-select-label">Staff Name</InputLabel>
                        <Select
                            size="small"
                            sx={{minWidth: 200}}
                            labelId="staffList"
                            id="staffList"
                            label="Staff Name"
                            onChange={handleStaffChange}
                        >
                            {staffList.map((item)=>{
                                return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </div>

                <TheDatePicker onChange={handleDateChange} defaultIsToday/>

                <Button color="primary" variant="contained" sx={{mt: 2}} onClick={()=>generateReport()}>Generate</Button>
            </ContentLayout>

            <Box sx={{mt: 2}}></Box>

            { selectedRecord?.name != undefined && 
                <ContentLayout title={selectedRecord.name + " - " + selectedRecord.date}>
                    <WorkerRecord empInfo={selectedRecord}/>
                </ContentLayout>
            }
        </MainLayout>
    )
}