import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const drawerWidth = 240;

function ContentLayout({children, title="", addBtnName="", handleClickAdd}) {
  return (
    <Paper sx={{ p: 1.5, display: 'flex', flexDirection: 'column'}}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        {title && 
          <Typography component="h2" variant="h5" color="primary">
            {title}
          </Typography>
        }

        {addBtnName && 
          <Button variant="contained" color="primary" onClick={handleClickAdd}>
            {addBtnName}
          </Button>
        }
      </Box>

      {children}
    </Paper>
  );
}

export default ContentLayout;
