// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD7GFRqhfVynP-OYfu5zyELAMBQeLQWC1o",
  authDomain: "mitoto-31f84.firebaseapp.com",
  projectId: "mitoto-31f84",
  storageBucket: "mitoto-31f84.appspot.com",
  messagingSenderId: "387657836716",
  appId: "1:387657836716:web:3c477111ffee06e796d709",
  measurementId: "G-H4B61XNR1K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
export const auth = getAuth(app);
export const db = getFirestore(app);