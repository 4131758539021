import MainLayout from "../../components/mainLayout";
import ContentLayout from "../../components/contentLayout";

export default function index(){    
    return (
        <MainLayout name="Dashboard">
            <ContentLayout title="Dashboard">
                <h2>Content</h2>
            </ContentLayout>
        </MainLayout>
    )
}