import React, {useState, useEffect} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import moment from 'moment';

import {db} from '../../firebase';
import { collection, getDocs } from "firebase/firestore"; 

import MainLayout from "../../components/mainLayout";
import ContentLayout from "../../components/contentLayout";

import AddEditEmployee from './addEditEmployee';

export default function Index(){
    const [rows, setRows] = useState([]);
    const [openModal, setOpenModal] = useState("");
    const [selectedItem, setSelectedItem] = useState({});

    useEffect(()=>{
        fetchData();
    },[]);

    const columns = [
        { field: 'id', headerName: 'No.', width: 70},
        { field: 'usrId', headerName: 'User ID'},
        { field: 'ic', headerName: 'IC No.'},
        { field: 'dob', headerName: 'DOB', width: 100},
        { field: 'name', headerName: 'Name', width:150 },
        { field: 'salary', headerName: 'Salary (RM)', width:100},
        { field: 'position', headerName: 'Position', flex: 1},
        { field: 'age', headerName: 'Age', width: 50},
        { field: 'phone', headerName: 'Phone', flex: 1},
        { field: 'updatedAt', headerName: 'Updated At', flex: 1},
        { field: 'action', headerName: 'Action', width: 100, renderCell: (params) => {
            return (
                <div>
                    <IconButton variant="contained" onClick={()=>openEditModal(params.row)}><ModeEditIcon /></IconButton>
                    {/* <IconButton variant="contained" onClick={()=>alert('delete')}><DeleteIcon /></IconButton> */}
                </div>
            )
        }},
    ];

    const openEditModal = (params) =>{
        setSelectedItem(params);
        setOpenModal("E");
    }

    async function fetchData(){
        console.log('fetch employee data');
        let newRows = [];
        let idx = 0;
        const querySnapshot = await getDocs(collection(db, "PCSystem", "Mitoto", "Employee"));
        querySnapshot.forEach((doc) => {
            idx = idx + 1;

            let curRow = {
                id: idx,
                usrId: doc.id,
                ic: doc.data().ic,
                updatedAt: doc.data().updatedAt,
                name: doc.data().name,
                salary: doc.data().salary,
                position: doc.data().position,
                phone: doc.data().phone,
                dob: doc.data().dob ? moment(doc.data().dob).format('DD/MM/YYYY') : "",
                updatedDate: doc.data().updatedAt ? new Date(doc.data().updatedAt).toLocaleString() : "-",
                age: doc.data().dob ? new Date().getFullYear() - new Date(doc.data().dob).getFullYear() : "-",
            }
            newRows.push(curRow);
        });

        setRows(newRows);
    }

    const handleOnClose = (isSuccess) =>{
        if(isSuccess == true) fetchData();

        setSelectedItem({});
        setOpenModal("");
    }

    return (
        <MainLayout name="Employee">
            <ContentLayout title="Employee List" addBtnName="Add Employee" handleClickAdd={()=>setOpenModal("A")}>
                <div style={{ minHeight: 170, width: '100%' }}>
                    <DataGrid 
                        disableRowSelectionOnClick
                        rows={rows} 
                        columns={columns} 
                        initialState={{
                            columns: {
                              columnVisibilityModel: {
                                // Hide columns status and traderName, the other columns will remain visible
                                usrId: false,
                                ic: false,
                                updatedAt: false
                              },
                            },
                        }}
                    />
                </div>
            </ContentLayout>

            <AddEditEmployee visibleType={openModal} data={selectedItem} returnClose={(res)=>handleOnClose(res)}/>
        </MainLayout>
    )
}