import React, {useState, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import DashboardIcon from '@mui/icons-material/Dashboard';
import BadgeIcon from '@mui/icons-material/Badge';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import AlarmIcon from '@mui/icons-material/Alarm';
import GroupIcon from '@mui/icons-material/Group';

import {useNavigate} from 'react-router-dom';

import {onAuthStateChanged, signOut} from 'firebase/auth';
import {auth} from '../firebase';

const drawerWidth = 240;

function MainLayout({window, children}) {
  const [GROUPS, setGROUPS] = useState(
    [
      {
        name: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard', subGroups: [], isOpened: false
      },
      {
        name: 'Employee', icon: <BadgeIcon />, path: '', isOpened: true,
        subGroups: [
          { name: 'Employee List', icon: <GroupIcon />, path: '/employee' },
          { name: 'Employee Report', icon: <PunchClockIcon />, path: '/employee/employeeReport' },
          { name: 'Clock In/Out List', icon: <AlarmIcon />, path: '/employee/clockInOut' },
          // { name: 'Check Payslip', icon: <LocalAtmIcon />, path: '/payslip' },
        ]
      },
      {
        name: 'Admin List', icon: <AdminPanelSettingsIcon />, path: '/admin', subGroups: [], isOpened: false
        // name: 'Employer', icon: <AdminPanelSettingsIcon />, path: '/employee', isOpened: false,
        // subGroups: [
        //   { name: 'Admin List', icon: <MailIcon />, path: '/admin' },
        // ]
      },
      {
        name: 'Settings', icon: <SettingsIcon />, path: '/settings', subGroups: [], isOpened: false,
      }
    ]
  );

  const theme = createTheme({
    palette: {
      background: {
        default: "#F3F6F999"
      },
      // primary: {
      //   main: '#444444',
      // },
      secondary: {
        main: '#8C8C8C',
      }
    },
  });

  const history = useNavigate();

  const [user, setUser] = useState(null);

  useEffect(()=>{
    onAuthStateChanged(auth, (user)=>{
      if(user){
        setUser(user);
      }else{
        setUser(null);
        history('/');
      }
    });
  }, []);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const logout = () =>{
    signOut(auth).then(()=>{
      alert('logged out');
    }).catch((error)=>{alert('logout error!');console.log(error)});;
  }

  const openPunchPage = () =>{
    history('/punchCard');
  };

  const toggleCollapse = (ignoreThis, idx) =>{
    if(!ignoreThis){
      let curGrp = GROUPS;
      curGrp[idx].isOpened = !curGrp[idx].isOpened;

      setGROUPS([...curGrp]);
    }
  }

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {GROUPS.map((cur, idx) => (
          <Box key={idx}>
            <ListItemButton key={cur.name} onClick={()=>toggleCollapse(!cur.subGroups.length > 0, idx)}>
              <ListItemIcon>
                {cur.icon}
              </ListItemIcon>
              <ListItemText primary={cur.name} />
              {
                cur.subGroups.length > 0 && (GROUPS[idx].isOpened ? <ExpandMore /> : <ExpandLess />)
              }
            </ListItemButton>
          
            {cur.subGroups.length > 0
              && (
                <Collapse key={"collapse_" + cur.name} in={GROUPS[idx].isOpened} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {cur.subGroups.map((sg)=>
                      <ListItemButton key={sg.path} sx={{ pl: 4 }} onClick={()=>history(sg.path)}>
                        <ListItemIcon>
                          {sg.icon}
                        </ListItemIcon>
                        <ListItemText primary={sg.name} />
                      </ListItemButton>
                    )}
                  </List>
                </Collapse>
            )}
          </Box>
        ))}
      </List>
      <Divider />
      <List key="list2">
        <ListItem key="punchCard" disablePadding>
          <ListItemButton onClick={()=>openPunchPage()} sx={{backgroundColor: 'lime'}}>
            <ListItemIcon>
            <AlarmIcon />
            </ListItemIcon>
            <ListItemText primary={"Clock In / Clock Out"} />
          </ListItemButton>
        </ListItem>

        <ListItem key="logout" disablePadding>
          <ListItemButton onClick={()=>logout()}>
            <ListItemIcon>
            <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={"logout"} />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  if(user){
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
            }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                Hi, Boss!
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onTransitionEnd={handleDrawerTransitionEnd}
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
          >
            <Toolbar />
    
            {children}
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

export default MainLayout;
